import React, { useEffect, useState } from "react";
import ProfilePic from "../assets/profile/dummy.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  membersList,
  setSortBy,
  setSortOrder,
  setCurrentPage,
  updateUserStatus,
} from "../store/Members/membersSlice";
import { Pagination } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import UpdatePasswordPopup from "../popups/updatePasswordPopup";
import AccountDeletionModal from "../popups/accountDeletionModel";
import ViewProfilePopup from "../popups/ViewProfilePopup"; // Import ViewProfilePopup
import QuickEditsPopup from "../popups/QuickEditsPopup";

const Members = () => {
  const dispatch = useDispatch();
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [viewProfileModalOpen, setViewProfileModalOpen] = useState(false); // State for ViewProfilePopup
  const [quickEditsModalOpen, setQuickEditsModalOpen] = useState(false); // State for QuickEditsPopup
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [memberType, setMemberType] = useState("");
  const [empStatus, setEmpStatus] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const BaseUrlProfile = process.env.REACT_APP_BASH_IMG_URL;
  const handleSearch = () => {
    let filteredList = list;
    console.log("filter ", { searchName, memberType, empStatus });
    if (searchName) {
      filteredList = filteredList?.filter((member) =>
        member.name.toLowerCase().includes(searchName.toLowerCase())
      );
    }

    if (memberType) {
      filteredList = filteredList?.filter(
        (member) => member.role.toLowerCase() === memberType.toLowerCase()
      );
    }

    if (empStatus) {
      const statusBool = empStatus === "approved";
      filteredList = filteredList?.filter(
        (member) => member.status === statusBool
      );
    }
    setFilteredMembers(filteredList);
    // Dispatch or set the filtered list to update the display
  };

  useEffect(() => {
    handleSearch();
  }, [empStatus, memberType, searchName]);

  const {
    list,
    status,
    sortBy,
    sortOrder,
    currentPage,
    itemsPerPage,
  } = useSelector((state) => state.members);
  const totalItems = filteredMembers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedList = filteredMembers.slice(startIndex, endIndex);

  const togglePasswordModal = () => {
    setPasswordModalOpen((prev) => !prev);
    if (deletionModalOpen) {
      setDeletionModalOpen(false);
    }
  };

  const resetFilter = () => {
    setSearchName("");
    setMemberType("");
    setEmpStatus("");
  };
  const toggleDeletionModal = () => {
    setDeletionModalOpen((prev) => !prev);
    if (passwordModalOpen) {
      setPasswordModalOpen(false);
    }
  };

  const handleUpdatePassword = (user) => {
    setSelectedUser(user);
    setDeletionModalOpen(false);
    setPasswordModalOpen(true);
  };

  const handleDeleteAccount = (user) => {
    setSelectedUser(user);
    setPasswordModalOpen(false);
    setDeletionModalOpen(true);
  };

  const handleViewProfile = (user) => {
    setSelectedUser(user);
    setViewProfileModalOpen(true); // Open ViewProfilePopup
  };

  const handleQuickEdits = (user) => {
    setSelectedUser(user);
    setQuickEditsModalOpen(true); // Open QuickEditsPopup
  };

  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
      dispatch(membersList());
  }, []);

  useEffect(() => {
    setFilteredMembers(list);
  }, [list]);

  const handleSort = (column) => {
    dispatch(setSortBy(column));
    dispatch(setSortOrder(sortOrder === "asc" ? "desc" : "asc"));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleStatusClick = (member) => {
    dispatch(updateUserStatus({ userId: member._id, newStatus: true }));
  };

  const handleDropdownAction = (action, user) => {
    console.log("action : ", action);
    switch (action) {
      case "change-password":
        handleUpdatePassword(user);
        break;
      case "delete-account":
        handleDeleteAccount(user);
        break;
      case "view-profile":
        handleViewProfile(user);
        break;
      case "quick-edits":
        handleQuickEdits(user);
        break;
      default:
        break;
    }
    setOpenDropdown(null);
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 p-4">
        <div className="row rounded">
          <div className="col-md-12 p-3 pt-4">
            <div className="row">
              <div className="col-md-4 d-flex align-items-center">
                <label htmlFor="memberName" className="w-50">
                  Search Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="memberName"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  value={memberType}
                  onChange={(e) => setMemberType(e.target.value)}
                >
                  <option value="">Member Type</option>
                  <option value="vender">Vendor</option>
                  <option value="employee">Employee</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-control"
                  value={empStatus}
                  onChange={(e) => setEmpStatus(e.target.value)}
                >
                  <option value="">Status</option>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
              <div className="col-md-2 d-flex justify-content-between">
                <button
                  className="btn btn-primary normalBtn px-4"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <button className="btn btn-danger px-3" onClick={resetFilter}>
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 table-container p-0 m-0 mt-5">
            <table className="custom-table w-full divide-y divide-gray-200">
              <thead>
                <tr className="">
                  <th className="px-4 py-2 text-center">
                    <input type="checkbox" />
                  </th>
                  <th
                    className="px-4 py-2 cursor-pointer"
                    onClick={() => handleSort("status")}
                  >
                    Status{" "}
                    {sortBy === "status" && (sortOrder === "asc" ? "🔼" : "🔽")}
                  </th>
                  <th
                    className="px-4 py-2 cursor-pointer"
                    onClick={() => handleSort("contact")}
                  >
                    Contact Information{" "}
                    {sortBy === "contact" &&
                      (sortOrder === "asc" ? "🔼" : "🔽")}
                  </th>
                  <th
                    className="px-4 py-2 cursor-pointer"
                    onClick={() => handleSort("membership")}
                  >
                    Membership{" "}
                    {sortBy === "membership" &&
                      (sortOrder === "asc" ? "🔼" : "🔽")}
                  </th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {paginatedList?.length ? (
                  paginatedList.map((member, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-4 py-2 text-center">
                        <input type="checkbox" />
                      </td>
                      <td className="px-4 py-2">
                        <img
                          src={
                            member?.image
                              ? `${BaseUrlProfile}/profile/${member?.image}`
                              : ProfilePic
                          }
                          alt="Avatar"
                          className="avatar"
                        />
                        {member.status === true ? (
                          <span className="status-label active ">Approved</span>
                        ) : (
                          <span
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Approve this memeber"
                            className="status-label active cursor-pointer"
                            onClick={() => handleStatusClick(member)}
                          >
                            Pending
                          </span>
                        )}
                      </td>
                      <td className="px-4 py-2">
                        <div>{member?.profile?.restaurantsName}</div>
                        <div>{member?.profile?.mobile}</div>
                        <div>{member.email}</div>
                        <div>{member.name}</div>
                        <div>{member.phone}</div>
                        <div>{member.address}</div>
                      </td>
                      <td className="px-4 py-2">
                        <div>{member.membership}</div>
                        <div>
                          Join Date{" "}
                          {member.subscription?.membershipId
                            ? new Date(
                                member.subscription?.membershipId?.createdAt
                              ).toDateString()
                            : ""}
                        </div>
                        <div>
                          Plan Detail:{" "}
                          {member.subscription?.membershipId?.title}
                        </div>
                        <div>Category: {member?.profile?.category}</div>
                      </td>
                      <td className="px-4 py-2 position-relative d-flex mt-3">
                        <button
                          className="actions-button px-4"
                          style={{ background: "#f7f7f7" }}
                          onClick={() => toggleDropdown(index)}
                        >
                          Actions{" "}
                          <svg
                            className="arrow-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M7 10l5 5 5-5z" />
                          </svg>
                        </button>

                        <div
                          className={`dropdown-menu rounded-0 ${
                            openDropdown === index ? "d-block" : " "
                          } actionDropDown`}
                          style={{ background: "#f7f7f7" }}
                        >
                          <div className="row">
                            <div className="col-sm-6">
                              <li
                                onClick={() =>
                                  handleDropdownAction("Account Detail",member)
                                }
                              >
                                Account Detail
                              </li>
                              <li
                                onClick={() =>
                                  handleDropdownAction("quick-edits",member)
                                }
                              >
                                Quick Edits
                              </li>
                              <li
                                onClick={() =>
                                  handleDropdownAction("Upgrade Membership",member)
                                }
                              >
                                Upgrade Membership
                              </li>
                            </div>
                            <div className="col-sm-6">
                              <li
                                onClick={() =>
                                  handleDropdownAction("view-profile",member)
                                }
                              >
                                View Profile
                              </li>
                              <li
                                onClick={() =>
                                  handleDropdownAction(
                                    "change-password",
                                    member
                                  )
                                }
                              >
                                Change Password
                              </li>
                              <li
                                className="delete-account"
                                onClick={() =>
                                  handleDropdownAction("delete-account", member)
                                }
                              >
                                Delete Account
                              </li>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-4 py-2 text-center" colSpan="5">
                      <p>No Record Found.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {filteredMembers.length > 10 ? (
              <Pagination>
                {[...Array(totalPages).keys()].map((page) => (
                  <Pagination.Item
                    key={page + 1}
                    active={page + 1 === currentPage}
                    onClick={() => handlePageChange(page + 1)}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            ) : (
              ""
            )}
            {selectedUser && (
              <>
                <UpdatePasswordPopup
                  isOpen={passwordModalOpen}
                  toggle={togglePasswordModal}
                  user={selectedUser}
                />
                <AccountDeletionModal
                  isOpen={deletionModalOpen}
                  toggle={toggleDeletionModal}
                  user={selectedUser}
                />
                <ViewProfilePopup
                  isOpen={viewProfileModalOpen}
                  toggle={() => setViewProfileModalOpen(false)}
                  user={selectedUser}
                />
                <QuickEditsPopup
                  isOpen={quickEditsModalOpen}
                  toggle={() => setQuickEditsModalOpen(false)}
                  user={selectedUser}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Members;
