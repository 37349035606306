import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmployeesByVendor,
  membersList,
  fetchEmployeeAssignedJobs,
} from "../store/Members/membersSlice";
import { loadEmployeeTracker, submitTimeTracker } from "../store/Members/trackerSlice";
import { formatDateTime, formatTime, processOverTimeData, processWeeklyData } from "../Helper/functions";
import { Button } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { showToast } from "../Helper/alert";
import { CSVLink } from "react-csv";

const TimeTracker = () => {
  const dispatch = useDispatch();
  const [vendor, setVendor] = useState("");
  const [employee, setEmployee] = useState("");
  const [employeeJob, setEmployeeJob] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const {
    activeMemberslist,
    employees,
    JobsList,
    status,
    assignedJobsToEmployee,
  } = useSelector((state) => state.members);
  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);
  const [data, setData] = useState([]);
  const handlePopupSubmit = (e) => {
    e.preventDefault();
    dispatch(submitTimeTracker({
      jobId: selectedJob.jobId,  // Assuming `selectedJob._id` is the job id
      startTime ,
      endTime ,
      date:selectedJob.sessionDate
    }))
    .unwrap()
    .then(() => {
      setShowModal(false);

      dispatch(
        loadEmployeeTracker({ employee, employeeJob, startDate, endDate })
      );

      setShowModal(false);
    })
    .catch((error) => {
      console.error('Failed to submit time data:', error);
      showToast(error.message)
    });

  };
  useEffect(()=>{
      console.log('startTime: ' ,startTime)
      console.log('endTime: ' ,endTime)
      console.log('selectedJob: ' ,selectedJob)
  },[startTime,endTime,selectedJob])

  const { JobsTracking } = useSelector((state) => state.timeTracker);

  useEffect(() => {
    if (status === "idle") {
      dispatch(membersList());
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (vendor) {
      dispatch(fetchEmployeesByVendor(vendor));
    }
  }, [vendor, dispatch]);

  useEffect(() => {
    if (employee) {
      dispatch(fetchEmployeeAssignedJobs({ vendor, employee }));
    }
  }, [employee, dispatch]);

  useEffect(() => {
    console.log("assignedJobsToEmployee : ", assignedJobsToEmployee);
  }, [assignedJobsToEmployee, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ vendor, employee, employeeJob, startDate, endDate });
    dispatch(
      loadEmployeeTracker({ employee, employeeJob, startDate, endDate })
    );
  };


  useEffect(() => {
    let newData = processOverTimeData(
      JobsTracking
    );
    setData(newData);
  }, [JobsTracking]);


  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 p-4">
        <h4 className="card-title text-left mb-3">Filter</h4>
        <div className="row rounded">
          <div className="card p-3 mb-4">
            <form className="row g-3 py-3" onSubmit={handleSubmit}>
              <div className="col-md-3">
                <label htmlFor="memberName" className="w-50 pb-2">
                  Search Vendor
                </label>
                <select
                  className="form-select"
                  value={vendor}
                  onChange={(e) => setVendor(e.target.value)}
                >
                  <option value="">Select</option>
                  {activeMemberslist?.map((vendor, index) => (
                    <option key={vendor._id} value={vendor._id}>
                      {vendor.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label htmlFor="memberName" className="w-50 pb-2">
                  Employee
                </label>
                <select
                  className="form-select"
                  value={employee}
                  onChange={(e) => setEmployee(e.target.value)}
                >
                  <option value="">Select</option>
                  {employees?.map((employee, index) => (
                    <option key={employee._id} value={employee._id}>
                      {employee.newWayName != ' ' ? employee.newWayName : employee.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="selectJob" className="w-50 pb-2">
                  Select Job
                </label>
                <select
                  className="form-select"
                  value={employeeJob}
                  onChange={(e) => setEmployeeJob(e.target.value)}
                >
                  <option value="">Select</option>
                  {assignedJobsToEmployee?.map((job, index) => (
                    <option key={job._id} value={job._id}>
                      {job.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-2">
                <label htmlFor="memberName" className="w-50 pb-2">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="col-md-2">
                <label htmlFor="memberName" className="w-50 pb-2">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              <div className="col-md-12 text-center ">
                <button type="submit" className="btn btn-success m-2">
                  Submit
                </button>
                <button
                  type="reset"
                  className="btn btn-light   m-2"
                  onClick={() => {
                    setVendor("");
                    setEmployee("");
                    setEmployeeJob("");
                    setStartDate("");
                    setEndDate("");
                  }}
                >
                  Clear
                </button>
              </div>
            </form>
          </div>

          {/* Time Tracker Information Section */}
          <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
            <h5>Time Tracker Information</h5>
            <CSVLink
            data={data}
            filename="data.csv"
            className="btn btn-success"
          >
            Export Sheet
          </CSVLink>
          </div>
          {/* Table */}
          <table className="custom-table w-full divide-y divide-gray-200 timetracker">
            <thead>
              <tr>
                <th>Job Name</th>
                <th>Total Amount</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Total Time</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {JobsTracking.length ? (
                JobsTracking?.map((task, index) => (
                  <tr key={index}>
                    <td className=" py-4 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">
                      {task.jobDetails.name}
                    </td>
                    <td className=" ">${task?.amount ?? "0:00"}</td>
                    <td className=" ">{formatDateTime(task?.startTime)}</td>
                    <td className=" ">
                      {task?.stoppedTime
                        ? formatDateTime(task?.stoppedTime)
                        : "00:00:00"}
                    </td>
                    <td className=" ">{formatTime(task.elapsedTime)}</td>
                    <td
                      onClick={() => {
                        setSelectedJob(task);
                        setShowModal(true);
                      }}
                      className="cursor-pointer whitespace-nowrap text-sm text-gray-500 text-center "
                    >
                      <Button type="button" className="btn btn-success">
                      Update Timer
                    </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} align="center" className="text-center">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal for entering start and end time */}
      {selectedJob && (
        <Modal
        centered 
          show={showModal}
          onHide={() => setShowModal(false)}
          className="custom-modal custom-view-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="mb-0">Edit Time Detail</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=" px-5 py-4">
            <form onSubmit={handlePopupSubmit}>
              <div className="row">
                <div className="form-group mb-3 col-md-6 col-sm-12">
                  <label htmlFor="startTime">Start Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="startTime"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mb-3 col-md-6 col-sm-12">
                  <label htmlFor="endTime">End Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="endTime"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-12 text-center my-3">
                  <div>
                    <Button type="submit" className="btn btn-success">
                      Submit
                    </Button>
                    <Button type="submit" className="btn btn-secondry" style={{marginLeft:'7px'}}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default TimeTracker;
